import { format, addDays, addMonths } from 'date-fns';
import { es } from 'date-fns/locale';

export const getTimeFromSeconds = (time)=>{
    // Hours, minutes and seconds
    const dias = ~~(time / (3600*24));// eslint-disable-line
    const hrs = ~~(time / 3600);// eslint-disable-line
    const mins = ~~((time % 3600) / 60);// eslint-disable-line
    const secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (dias > 0) {
        ret += `${dias} dias`;
    }else if (hrs > 0) {
        ret += `${hrs} horas`;
    }else if(mins>0){
        ret += `${mins} minutos`;
    }else if(secs>0){
        ret += `${secs} segundos`;
    }else{
        ret += " ahora mismo";
    }
    return ret;
}

export const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

export const isTimeBetween = (startTime, endTime, checkTime) => {
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);
    const checkMinutes = timeToMinutes(checkTime);

    // Verificar si checkMinutes está entre startMinutes y endMinutes
    return startMinutes <= checkMinutes && checkMinutes <= endMinutes;
};

export const stringToDate = (value) => {
  const dt = value.substring(0, 2);
  const mon = value.substring(3, 5);
  const yr = value.substring(6, 10);
  const date = new Date(`${yr}-${mon}-${dt}`);
  return date
};

export const addDaysFromString = (dateString, offset) =>{
  const date = new Date(dateString);
  const adjustedDate = addDays(date, offset);
  const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
  return formattedDate;
}

export const addMonthFromString = (dateString, offset) =>{
  const date = new Date(dateString);
  const adjustedDate = addMonths(date, offset);
  const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
  return formattedDate;
}

export const addDaysFromDate = (dateValue, offset) =>{
  const adjustedDate = addDays(dateValue, offset);
  return adjustedDate;
}

export const stringDateToDate = (value) => {
  /* const dt = value.substring(8, 10);
  const mon = value.substring(5, 7);
  const yr = value.substring(0, 4);
  const date = new Date(`${yr}-${mon}-${dt}`); */
  const date = new Date(value);
  return date
};

export const formatDateToSpanish = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "EEEE, d 'de' MMMM 'de' yyyy", { locale: es });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export const formatDateMonthToSpanish = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "MMMM", { locale: es });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export const stringToDatetime = (value) => {
  const dt = value.substring(0, 2);
  const mon = value.substring(3, 5);
  const yr = value.substring(6, 10);
  const hr = value.substring(11, 13);
  const mi = value.substring(14, 16);
  let date;
  if(value.length>16){
    const sg = value.substring(17, 19);
    date = new Date(`${yr}-${mon}-${dt} ${hr}:${mi}:${sg}`);
  }else{
    date = new Date(`${yr}-${mon}-${dt} ${hr}:${mi}`);
  }
  return date
};

export const dateToString = (value) => {
  if(value === ''){
    return '';
  }
  let day = `${value.getDate()}`;
  let month = `${(value.getMonth() + 1)}`;
  let year = `${value.getFullYear()}`;
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  return `${day}-${month}-${year}`;
};

export const datetimeToString = (value) => {
  let day = `${value.getDate()}`;
  let month = `${(value.getMonth() + 1)}`;
  let year = `${value.getFullYear()}`;
  let hour = `${value.getHours()}`;
  let minutes = `${value.getMinutes()}`;
  let seconds = `${value.getSeconds()}`;
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);
  return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`;
};

export const dateToStringDate = (value) => {
  let day = `${value.getDate()}`;
  let month = `${(value.getMonth() + 1)}`;
  let year = `${value.getFullYear()}`;
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  return `${year}-${month}-${day}`;
};

export const datetimeToStringDatetime = (value) => {
  let day = `${value.getDate()}`;
  let month = `${(value.getMonth() + 1)}`;
  let year = `${value.getFullYear()}`;
  let hour = `${value.getHours()}`;
  let minutes = `${value.getMinutes()}`;
  let seconds = `${value.getSeconds()}`;
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);
  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
};

export const compareDates = (first,second) =>{
  if(first.getTime() < second.getTime()){// Si el primero es menor devuelvo -1
    return -1
  }
  if(first.getTime() > second.getTime()){// Si el primero es mayor devuelvo 1
    return 1
  }// Si son iguales devuelvo 0
    return 0
}

export const secondsToTime = (time) =>{
    // Hours, minutes and seconds
    let dias = ~~(time / (3600*24));// eslint-disable-line
    let hrs = ~~(time / 3600);// eslint-disable-line
    let mins = ~~((time % 3600) / 60);// eslint-disable-line
    const secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (dias > 0) {
        ret += `${dias} dias `;
    }if (hrs > 0) {
        ret += `${hrs} h `;
    }if(mins>0){
        ret += `${mins} min `;
    }if(secs>0){
        ret += `${secs} segs`;
    }
    if(ret===''){
        ret="0 segs";
    }
    return ret;
}

export const getWeeksBetween = (f1,f2) =>{
  const diferencia = f2.getTime() - f1.getTime();
  const semanas = diferencia / 1000 / 60 / 60 / 24 / 7;
  return semanas;
}

export const getToday = (format) =>{
  const date=new Date()
  switch(format){
    case 'date':
      return dateToStringDate(date)
    case 'datetime':
      return datetimeToStringDatetime(date)
    case 'string':
      return dateToString(date)
    case 'stringtime':
      return datetimeToString(date)
    default:
      return null;
  }
}

function checkZero(data){
  if(data.length === 1){
    data = `0${data}`;
  }
  return data;
}

export const timeFromDate = (date) =>date.substring(11,16);

export const yearFromDate = (date) => date.substring(0,4);

export const dayNameFromDate = (date) =>{
    const dat = new Date(date)
    return `${capitalize(dat.toLocaleDateString('es-ES', { weekday: 'long' }))}, ${dat.getDate()}`
}

export const monthNameFromDate = (date) =>{
    const dat = new Date(date)
    return capitalize(dat.toLocaleDateString('es-ES', { month: 'long' }))
}

export const monthFromDate = (date) =>{
    const dat = new Date(date)
    const month = dat.getMonth() + 1
    return checkZero(`${month}`)
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
